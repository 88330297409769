




















































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { DigInputController, DigInputPhone } from '@digithia/input'
import TheToasterWP from '@/components/toast/TheToasterWP.vue'

export interface RegistrationForm {
  sonprenom: string
  sonnom: string
  sonmail: string
  sonadresse: string
  saville: string
  soncodepostal: string
  sonportable: string
  bienadresse: string
  bienville: string
  biencodepostal: string
  biendateliv: string
  songestionnaire: string
  sonsiret: string
  centreimpot: string
  songenre: string
  sadatenais: string
  declaration: null | number
  rgpd: boolean
}

const defaultForm = {
  sonprenom: '',
  sonnom: '',
  sonmail: '',
  sonadresse: '',
  saville: '',
  soncodepostal: '',
  sonportable: '',
  bienadresse: '',
  bienville: '',
  biencodepostal: '',
  biendateliv: '',
  songestionnaire: '',
  sonsiret: '',
  centreimpot: '',
  songenre: '',
  sadatenais: '',
  declaration: null,
  rgpd: false,
}

@Component({
  name: 'ViewRegistrationForm',
  components: {
    TheToasterWP,
  },
})
export default class Main extends Vue {
  form: RegistrationForm = JSON.parse(JSON.stringify(defaultForm))
  loading = false
  finish = false

  update(field: keyof RegistrationForm, value: any) {
    this.form[field] = value as never
  }

  reset() {
    this.finish = false
    this.form = JSON.parse(JSON.stringify(defaultForm))
    DigInputController.resetInputsValidity('.register-form')
  }

  async register() {
    const sonportable = document.querySelector('.sonportable') as DigInputPhone
    this.form.sonportable = sonportable.value

    if (!DigInputController.checkInputsValidity('.register-form')) {
      return this.$store.dispatch('toaster/wptoast', {
        message: `Le formulaire est invalide`,
        type: `warning`,
      })
    }
    if (!this.form.rgpd) {
      return this.$store.dispatch('toaster/wptoast', {
        message: `Vous devez accepter les conditions`,
        type: `warning`,
      })
    }
    this.loading = true
    const response = await this.$store.dispatch('preRegister', this.form)
    this.loading = false

    if (response.success) {
      this.$store.dispatch('toaster/wptoast', {
        type: `success`,
        message: `Votre compte a bien été enregistré`,
      })
      this.finish = true

      const wrapper = document.getElementById('register-form')
      console.log(wrapper)
      wrapper?.scrollIntoView({ block: 'start', inline: 'start' })
    } else {
      if (response.code === 'dup_entry') {
        this.$store.dispatch('toaster/wptoast', {
          type: 'error',
          message: `L'adresse mail saisie est déjà enregistrée sur Gérer Mon LMNP`,
        })
      } else {
        this.$store.dispatch('toaster/wptoast', response)
      }
    }
  }

  mounted() {
    const sonportable = document.querySelector('.sonportable') as DigInputPhone
    sonportable.errors.invalid = `Le téléphone est invalide`
  }
}
