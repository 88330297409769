























import { Component, Vue } from 'vue-property-decorator'
import { Toast } from '../../store/toaster'

@Component({ name: 'TheToasterWP' })
export default class TheToasterWP extends Vue {
  get toasts(): Toast[] {
    return this.$store.getters['toaster/wptoasts']
  }

  remove(toast: Toast) {
    this.$store.dispatch('toaster/wpremove', toast)
  }

  isInfo(toast: Toast): boolean {
    return (
      toast.type === 'info' ||
      !this.isSuccess(toast) ||
      !this.isWarning(toast) ||
      !this.isError(toast)
    )
  }

  isSuccess(toast: Toast): boolean {
    return toast.type === 'success'
  }

  isWarning(toast: Toast): boolean {
    return toast.type === 'warning'
  }

  isError(toast: Toast): boolean {
    return toast.type === 'error'
  }
}
